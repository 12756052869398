var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mx-2"},[_c('h3',{staticClass:"title-header noprint mt-5"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.autonomousteachingactivity", true, "Actividades de Trabajo Autónomo" ))+" "),_c('AlternativeNameButton',{attrs:{"table_name":'mesh.autonomousteachingactivity',"allows_crud":_vm.allows_crud}})],1),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('ButtonMedium',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_autonomousteachingactivity'),expression:"'mesh.add_autonomousteachingactivity'"}],attrs:{"tooltip_text":`Agregar ${_vm.$getVisibleNames(
            'mesh.autonomousteachingactivity',
            false,
            'Actividad de Trabajo Autónomo'
          )}`,"text_button":`Agregar`,"click_button":() => _vm.$bvModal.show(`new-autonomous-teaching-activity-modal`),"icon":'plus',"variant":'primary'}}):_vm._e()],1)]),(_vm.autonomous_teaching_activities.length > 0)?_c('GenericBTable',{attrs:{"filterCustom":_vm.filterCustom,"items":_vm.autonomous_teaching_activities,"fields":_vm.autonomousTeachingActivitiesFields,"filter":_vm.input_search,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud,"display_id":'autonomous_teaching_activities',"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"emitChangeSearchField":_vm.changeInputSearch,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"cell(description)",fn:function(row){return [_c('div',{staticClass:"rich-text-content",domProps:{"innerHTML":_vm._s(row.item.description)}})]}},(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('ButtonSmall',{directives:[{name:"can",rawName:"v-can",value:('mesh.change_autonomousteachingactivity'),expression:"'mesh.change_autonomousteachingactivity'"}],class:``,attrs:{"tooltip_text":`Editar ${_vm.$getVisibleNames(
            'mesh.autonomousteachingactivity',
            false,
            'Actividad de Trabajo Autónomo'
          )}`,"click_button":() =>
              _vm.$bvModal.show(
                `edit-autonomous-teaching-activity-modal-${row.item.id}`
              ),"icon":'square'}}),_c('ButtonSmall',{directives:[{name:"can",rawName:"v-can",value:('mesh.delete_autonomousteachingactivity'),expression:"'mesh.delete_autonomousteachingactivity'"}],attrs:{"click_button":() => _vm.askForDeleteAutonomousTeachingActivity(row.item.id),"tooltip_text":`Eliminar ${_vm.$getVisibleNames(
            'mesh.autonomousteachingactivity',
            false,
            'Actividad de Trabajo Autónomo'
          )}`,"icon":'trash'}}),_c('b-modal',{attrs:{"id":`edit-autonomous-teaching-activity-modal-${row.item.id}`,"title":"Editar Actividad de Trabajo Autónomo","size":"md","hide-footer":""}},[_c('AutonomousTeachingActivityForm',{attrs:{"AutonomousTeachingActivity":row.item,"institution_id":row.item.school,"show_title":false},on:{"updated":_vm.slotUpdatedAutonomousTeachingActivity}})],1)]}}:null],null,true)}):_c('div',[_c('br'),_c('strong',[_vm._v("No se a encontrado ninguna "+_vm._s(_vm.$getVisibleNames( "mesh.autonomousteachingactivity", false, "Actividad de Trabajo Autónomo" )))])])],1),_c('b-modal',{attrs:{"id":`new-autonomous-teaching-activity-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames(
      'mesh.autonomousteachingactivity',
      false,
      'Actividad de Trabajo Autónomo'
    )}`,"size":"lg"}},[_c('AutonomousTeachingActivityForm',{attrs:{"institution_id":_vm.user.school},on:{"created":_vm.slotCreatedAutonomousTeachingActivity}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }